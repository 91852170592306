import { ProductUtil } from "@oriola-origo/origo-common-client-lib";
import { ensureTrailingSlash } from "../url/url";
import { CaseType } from "../../constants/caseType";
import CaseItemsType from "../../constants/caseItemsType";
import { CaseStatus } from "../../constants/caseStatus";
import { RestService } from "../../components/common";
import { isOriolaUser } from "../../components/auth/permission";
import EventQueueTarget from "../../constants/eventQueueTarget";

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_RECLAMATION_SERVICE);
const reclamationApi = process.env.REACT_APP_RECLAMATION_API;
const ORIOLA_CUSTOMER_ID = "1";
const DEFAULT_PAGE_SIZE = 10;

export const createFetchPath = (
  startIndex = 0,
  pageSize = DEFAULT_PAGE_SIZE,
  filters = {},
  exportToExcel = false
) => {
  // base path
  let path = `${baseUrl}${reclamationApi}?offset=${startIndex}&pageSize=${pageSize}`;
  if (exportToExcel === true) {
    path = `${baseUrl}${reclamationApi}/excelReport?offset=${startIndex}&pageSize=${pageSize}`;
  }
  const {
    organizationId,
    organizationIds,
    caseStatus,
    caseSubStatus,
    caseType,
    startDate,
    endDate,
    handler,
    productDetail,
    profitCenters,
    supplierNumbers,
    text,
    sortType,
    subQueue,
  } = filters;

  if (organizationId != null) {
    path = `${path}&organizationId=${organizationId}`;
  }

  if (organizationIds != null && Array.isArray(organizationIds)) {
    path = `${path}&customerIds=${organizationIds.join(",")}`;
  }

  if (caseStatus != null) {
    path = `${path}&caseStatus=${caseStatus}`;
  }

  if (caseSubStatus != null) {
    path = `${path}&caseSubStatus=${caseSubStatus}`;
  }

  if (caseType != null) {
    path = `${path}&caseType=${caseType}`;
  }
  if (startDate != null) {
    path = `${path}&startDate=${startDate}`;
  }

  if (endDate != null) {
    path = `${path}&endDate=${endDate}`;
  }
  if (handler != null) {
    path = `${path}&handler=${handler}`;
  }

  if (
    (handler === EventQueueTarget.RETURN_DEPARTMENT ||
      handler === EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT ||
      handler === EventQueueTarget.SCC) &&
    subQueue
  ) {
    path = `${path}&subQueue=${subQueue}`;
  }

  if (productDetail != null) {
    path = `${path}&productDetail=${productDetail}`;
  }

  if (profitCenters != null) {
    path = `${path}&profitCenters=${profitCenters}`;
  }

  if (supplierNumbers != null) {
    path = `${path}&supplierNumbers=${supplierNumbers}`;
  }

  if (text != null) {
    path = `${path}&text=${text}`;
  }

  if (sortType != null) {
    path = `${path}&sortType=${sortType}`;
  }

  return path;
};

export const onlyOneItemAllowed = caseConfig =>
  caseConfig &&
  caseConfig.items &&
  caseConfig.items.minCount === 1 &&
  caseConfig.items.maxCount === 1;

export const getReasonIdentifiersConfig = (
  productReturn,
  ProductReturnReasonIdentifiersConfig
) => {
  const { returnValue } = productReturn;
  return returnValue
    ? ProductReturnReasonIdentifiersConfig.willReturnProduct
    : ProductReturnReasonIdentifiersConfig.noProductReturn;
};

export const getCaseConfig = (
  caseType,
  productReturn,
  reasonIdentifier,
  DamagedProductConfig,
  ProductReturnReasonIdentifiersConfig,
  SuspectedProductDefectConfig
) => {
  if (caseType === CaseType.DAMAGED_PRODUCT) {
    return (
      DamagedProductConfig &&
      (DamagedProductConfig[reasonIdentifier] || DamagedProductConfig.OTHER)
    );
  }
  if (caseType === CaseType.PRODUCT_DELIVERY) {
    if (!productReturn || !ProductReturnReasonIdentifiersConfig) {
      return null;
    }
    const reasonIdentifiersConfig = getReasonIdentifiersConfig(
      productReturn,
      ProductReturnReasonIdentifiersConfig
    );
    return reasonIdentifiersConfig[reasonIdentifier];
  }
  if (caseType === CaseType.SUSPECTED_PRODUCT_DEFECT) {
    return (
      SuspectedProductDefectConfig &&
      SuspectedProductDefectConfig[reasonIdentifier]
    );
  }
  return null;
};

export const getItemFieldsConfig = (itemType, caseConfig) => {
  switch (itemType) {
    case CaseItemsType.PRODUCT_ORDER:
      return caseConfig.productOrders;
    case CaseItemsType.ENTIRE_ORDER:
      return caseConfig.entireOrders;
    case CaseItemsType.SHIPPING_BOX:
      return caseConfig.shippingBoxes;
    case CaseItemsType.NO_ITEM:
      return caseConfig.noItem;
    default:
      return null;
  }
};

export const getProductOrderFieldsConfig = caseConfig =>
  getItemFieldsConfig(CaseItemsType.PRODUCT_ORDER, caseConfig);

export const getEntireOrderFieldsConfig = caseConfig =>
  getItemFieldsConfig(CaseItemsType.ENTIRE_ORDER, caseConfig);

export const getNoItemFieldsConfig = caseConfig =>
  getItemFieldsConfig(CaseItemsType.NO_ITEM, caseConfig);

export const getShippingBoxFieldsConfig = caseConfig =>
  getItemFieldsConfig(CaseItemsType.SHIPPING_BOX, caseConfig);

export const hasProductOrders = caseConfig =>
  caseConfig && caseConfig.productOrders;

export const hasEntireOrders = caseConfig =>
  caseConfig && caseConfig.entireOrders;

export const hasShippingBoxes = caseConfig =>
  caseConfig && caseConfig.shippingBoxes;

export const hasNoItem = caseConfig => caseConfig && caseConfig.noItem;

export const getEachTypesOfItems = (items = []) => {
  const productOrders = [];
  const entireOrders = [];
  const shippingBoxes = [];
  const noItems = [];

  items.forEach(item => {
    if (item.type === CaseItemsType.PRODUCT_ORDER) {
      productOrders.push(item);
    } else if (item.type === CaseItemsType.ENTIRE_ORDER) {
      entireOrders.push(item);
    } else if (item.type === CaseItemsType.SHIPPING_BOX) {
      shippingBoxes.push(item);
    } else if (item.type === CaseItemsType.NO_ITEM) {
      noItems.push(item);
    }
  });
  return { productOrders, entireOrders, shippingBoxes, noItems };
};

export const getFieldsConfig = reasonIdentifierConfig => {
  if (reasonIdentifierConfig) {
    return reasonIdentifierConfig.fields;
  }
  return null;
};

export const isOrder = (reasonIdentifierConfig, ProductReturnItemType) => {
  if (!reasonIdentifierConfig) {
    return false;
  }
  return (
    reasonIdentifierConfig.type === ProductReturnItemType.ORDER ||
    reasonIdentifierConfig.type === ProductReturnItemType.ORDER_AND_ENTIRE_ORDER
  );
};

export const isShippingBox = (
  reasonIdentifierConfig,
  ProductReturnItemType
) => {
  if (!reasonIdentifierConfig) {
    return false;
  }
  return reasonIdentifierConfig.type === ProductReturnItemType.SHIPPING_BOX;
};

export const isInformation = (
  reasonIdentifierConfig,
  ProductReturnItemType
) => {
  if (!reasonIdentifierConfig) {
    return false;
  }
  return reasonIdentifierConfig.type === ProductReturnItemType.INFORMATION;
};

export const filterCaseItems = (items = [], caseConfig = {}) =>
  items.filter(item => {
    if (
      item.type === CaseItemsType.PRODUCT_ORDER &&
      hasProductOrders(caseConfig)
    ) {
      return true;
    }
    if (
      item.type === CaseItemsType.ENTIRE_ORDER &&
      hasEntireOrders(caseConfig)
    ) {
      return true;
    }
    if (
      item.type === CaseItemsType.SHIPPING_BOX &&
      hasShippingBoxes(caseConfig)
    ) {
      return true;
    }
    if (item.type === CaseItemsType.NO_ITEM && hasNoItem(caseConfig)) {
      return true;
    }
    return false;
  });

export const isItemHandled = (_item, reclamationCase) =>
  reclamationCase.caseStatus === CaseStatus.SOLVED;

export const isCaseHandled = reclamationCase => {
  const { items = [] } = reclamationCase || {};
  return items.every(item => isItemHandled(item, reclamationCase));
};

export const getCaseHandlingData = reclamationCase => {
  const { items } = reclamationCase || {};
  if (items != null && items.length !== 0) {
    return items[0].handlingData;
  }
  return null;
};

export const isSpdCase = targetCase =>
  targetCase.caseType === CaseType.SUSPECTED_PRODUCT_DEFECT;

export const noValidItem = (items, caseConfig) => {
  let validItemCount = 0;
  if (hasProductOrders(caseConfig)) {
    validItemCount += items.filter(
      item => item.type === CaseItemsType.PRODUCT_ORDER
    ).length;
  }
  if (hasShippingBoxes(caseConfig)) {
    validItemCount += items.filter(
      item => item.type === CaseItemsType.SHIPPING_BOX
    ).length;
  }
  if (hasEntireOrders(caseConfig)) {
    validItemCount += items.filter(
      item => item.type === CaseItemsType.ENTIRE_ORDER
    ).length;
  }
  if (hasNoItem(caseConfig)) {
    validItemCount += items.filter(
      item => item.type === CaseItemsType.NO_ITEM
    ).length;
  }
  return validItemCount === 0;
};

export const filterDeprecatedReasonIdentifier = (
  reasons,
  editMode,
  reasonIdentifier,
  reasonIdentifiersConfig = {}
) =>
  reasons.filter(reason => {
    if (
      editMode &&
      reasonIdentifier === reason &&
      reasonIdentifiersConfig[reason] &&
      reasonIdentifiersConfig[reason].deprecated
    ) {
      return true;
    }
    return (
      reasonIdentifiersConfig[reason] &&
      !reasonIdentifiersConfig[reason].deprecated
    );
  });

export const fetchAndReserveCurrentCaseHandler = async reclamationId => {
  const reservePath = `${baseUrl}${reclamationApi}/${reclamationId}/reservation`;
  return RestService.put(reservePath);
};

export const unreserveCurrentCaseHandler = async reclamationId => {
  const unreservePath = `${baseUrl}${reclamationApi}/${reclamationId}/reservation`;
  RestService.delete(unreservePath);
};

export const getMarketingAuthorizationHolder = (
  language,
  targetProduct,
  nameOnly = false
) => {
  if (targetProduct == null) {
    return null;
  }

  let marketingAuthorizationHolder =
    ProductUtil.getMarketingAuthorizationHolder(targetProduct, language);

  // remove profit center number
  if (
    nameOnly === true &&
    marketingAuthorizationHolder &&
    marketingAuthorizationHolder.includes("-")
  ) {
    const numberAndName = marketingAuthorizationHolder.split("-");
    if (numberAndName.length === 2) {
      marketingAuthorizationHolder = numberAndName[1].trim();
    }
  }

  return marketingAuthorizationHolder;
};

export const isOfficeFile = contentType =>
  [
    "application/msword",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.template",
    "application/vnd.ms-word.document.macroEnabled.12",
    "application/vnd.ms-word.template.macroEnabled.12",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.template",
    "application/vnd.ms-excel.sheet.macroEnabled.12",
    "application/vnd.ms-excel.template.macroEnabled.12",
    "application/vnd.ms-excel.addin.macroEnabled.12",
    "application/vnd.ms-excel.sheet.binary.macroEnabled.12",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.openxmlformats-officedocument.presentationml.template",
    "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
    "application/vnd.ms-powerpoint.addin.macroEnabled.12",
    "application/vnd.ms-powerpoint.presentation.macroEnabled.12",
    "application/vnd.ms-powerpoint.template.macroEnabled.12",
    "application/vnd.ms-powerpoint.slideshow.macroEnabled.12",

    "application/vnd.ms-access",
  ].includes(contentType);

export const isCaseForOriolaByOriola = (customerId, user) =>
  customerId === ORIOLA_CUSTOMER_ID && isOriolaUser(user);

export const shouldShowSubmitFields = (
  reclamationCase,
  contactInfo,
  userData
) => {
  let { customerId } = reclamationCase || {};
  if (customerId == null) {
    const { customer } = contactInfo || {};
    customerId = (customer || {}).customerId;
  }
  return isCaseForOriolaByOriola(customerId, userData) === false;
};

export const checkIfNewReturnAddressFormat = returnAddress =>
  returnAddress && typeof returnAddress === "object";
