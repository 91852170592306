import React from "react";
import Icon from "@material-ui/core/Icon";
import PropTypes from "prop-types";

const styles = {
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {},
  modalDelete: {
    width: "80px",
  },
};

export function More() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./more/more.png")}
        srcSet={`
            ${require("./more/more@2x.png")} 2x,
            ${require("./more/more@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Info(props) {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="Info"
        src={require("./info/info.png")}
        srcSet={`
            ${require("./info/info@2x.png")} 2x,
            ${require("./info/info@3x.png")} 3x
          `}
        {...props}
      />
    </Icon>
  );
}

export function HealthForLife() {
  return (
    <img
      alt="Health for life"
      src={require("./healthForLife/health_for_life_f.png")}
      srcSet={`
          ${require("./healthForLife/health_for_life_f@2x.png")} 2x,
          ${require("./healthForLife/health_for_life_f@3x.png")} 3x
        `}
    />
  );
}

export function ArrowRight() {
  return (
    <img
      alt="Arrow right"
      src={require("./arrowRight/arrowRight.png")}
      srcSet={`
          ${require("./arrowRight/arrowRight@2x.png")} 2x,
          ${require("./arrowRight/arrowRight@3x.png")} 3x
        `}
    />
  );
}

export function Calendar() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="calendar"
        src={require("./calendar/calendar.png")}
        srcSet={`
            ${require("./calendar/calendar@2x.png")} 2x,
            ${require("./calendar/calendar@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Search({ style }) {
  return (
    <img
      style={style}
      alt="search"
      src={require("./search/search.svg").default}
    />
  );
}

Search.propTypes = {
  style: PropTypes.shape({}),
};

Search.defaultProps = {
  style: {},
};

export function Trashcan() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="trashcan"
        src={require("./trashcan/trashcan.png")}
        srcSet={`
            ${require("./trashcan/trashcan@2x.png")} 2x, 
            ${require("./trashcan/trashcan@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function ModalDeleteIcon() {
  return (
    <img
      style={styles.modalDelete}
      alt="modalDeleteIcon"
      src={require("./modalDeleteIcon/modalDeleteIcon@3x.png")}
    />
  );
}

export function DeleteIcon() {
  return <img alt="Delete" src={require("./trashcan/trashcan.svg").default} />;
}

export function Print() {
  return (
    <img
      alt="Print"
      src={require("./print/print.png")}
      srcSet={`
          ${require("./print/print@2x.png")} 2x,
          ${require("./print/print@3x.png")} 3x
        `}
    />
  );
}

export function Narcotics() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="narcotics"
        src={require("./narcotic/narcotic.png")}
        srcSet={`
            ${require("./narcotic/narcotic@2x.png")} 2x, 
            ${require("./narcotic/narcotic@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function RemoveHandlingDataIcon() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="remove-handling-data"
        src={require("./removeHandlingDataIcon/removeHandlingDataIcon.png")}
        srcSet={`
            ${require("./removeHandlingDataIcon/removeHandlingDataIcon@1.5x.png")} 1.5x, 
            ${require("./removeHandlingDataIcon/removeHandlingDataIcon@2x.png")} 2x
          `}
      />
    </Icon>
  );
}

export function PrinterSmallIcon({ style }) {
  return (
    <img
      style={style}
      alt="new case"
      src={require("./print/print.svg").default}
    />
  );
}
PrinterSmallIcon.propTypes = {
  style: PropTypes.shape({}),
};

PrinterSmallIcon.defaultProps = {
  style: {},
};

export function Posti({ style }) {
  return (
    <img style={style} alt="posti" src={require("./posti/posti.svg").default} />
  );
}
Posti.propTypes = {
  style: PropTypes.shape({}),
};

Posti.defaultProps = {
  style: {},
};

export function Postnord({ style }) {
  return (
    <img
      style={style}
      alt="postnord"
      src={require("./postnord/postnord.svg").default}
    />
  );
}
Postnord.propTypes = {
  style: PropTypes.shape({}),
};

Postnord.defaultProps = {
  style: {},
};
export function AddFile({ style }) {
  return (
    <img
      style={style}
      alt="add file"
      src={require("./addFile/addFile.svg").default}
    />
  );
}

AddFile.propTypes = {
  style: PropTypes.shape({}),
};

AddFile.defaultProps = {
  style: {},
};

export function Attachment({ style }) {
  return (
    <img
      style={style}
      alt="attachment"
      src={require("./attachment/attachment.svg").default}
    />
  );
}

Attachment.propTypes = {
  style: PropTypes.shape({}),
};

Attachment.defaultProps = {
  style: {},
};

export function CaseCreatedIcon({ style }) {
  return (
    <img
      style={style}
      alt="new case"
      src={require("./caseCreated/caseCreated.svg").default}
    />
  );
}

CaseCreatedIcon.propTypes = {
  style: PropTypes.shape({}),
};

CaseCreatedIcon.defaultProps = {
  style: {},
};

export function ReOpenCaseIcon({ style }) {
  return (
    <img
      style={style}
      alt="reopen case"
      src={require("./reOpenCase/reOpenCase.svg").default}
    />
  );
}

ReOpenCaseIcon.propTypes = {
  style: PropTypes.shape({}),
};

ReOpenCaseIcon.defaultProps = {
  style: {},
};

export function ChangeOueueIcon({ style }) {
  return (
    <img
      style={style}
      alt="change queue"
      src={require("./changeQueue/changeQueue.svg").default}
    />
  );
}

ChangeOueueIcon.propTypes = {
  style: PropTypes.shape({}),
};

ChangeOueueIcon.defaultProps = {
  style: {},
};

export function EditCaseIcon({ style }) {
  return (
    <img
      style={style}
      alt="edit case"
      src={require("./editCase/editCase.svg").default}
    />
  );
}

EditCaseIcon.propTypes = {
  style: PropTypes.shape({}),
};

EditCaseIcon.defaultProps = {
  style: {},
};

export function CopyCaseIcon({ style }) {
  return (
    <img
      style={style}
      alt="copy case"
      src={require("./copyCase/copyCase.svg").default}
    />
  );
}

CopyCaseIcon.propTypes = {
  style: PropTypes.shape({}),
};

CopyCaseIcon.defaultProps = {
  style: {},
};

export function UserOueueIcon({ style }) {
  return (
    <img
      style={style}
      alt="user queue"
      src={require("./changeQueue/userQueue.svg").default}
    />
  );
}

UserOueueIcon.propTypes = {
  style: PropTypes.shape({}),
};

UserOueueIcon.defaultProps = {
  style: {},
};

export function DamageProductIcon({ style }) {
  return (
    <img
      style={style}
      alt="damage product"
      src={require("./damageProductIcon/damageProductIcon.svg").default}
    />
  );
}

DamageProductIcon.propTypes = {
  style: PropTypes.shape({}),
};

DamageProductIcon.defaultProps = {
  style: {},
};

export function ProductDefectIcon({ style }) {
  return (
    <img
      style={style}
      alt="damage product"
      src={require("./productDefectIcon/productDefectIcon.svg").default}
    />
  );
}

ProductDefectIcon.propTypes = {
  style: PropTypes.shape({}),
};

ProductDefectIcon.defaultProps = {
  style: {},
};

export function ProductReturnIcon({ style }) {
  return (
    <img
      style={style}
      alt="damage product"
      src={require("./productReturnIcon/productReturnIcon.svg").default}
    />
  );
}

ProductReturnIcon.propTypes = {
  style: PropTypes.shape({}),
};

ProductReturnIcon.defaultProps = {
  style: {},
};

export function EditWarningIcon({ style }) {
  return (
    <img
      style={style}
      alt="damage product"
      src={require("./editWarning/editWarning.svg").default}
    />
  );
}

EditWarningIcon.propTypes = {
  style: PropTypes.shape({}),
};

EditWarningIcon.defaultProps = {
  style: {},
};

export function ImportExcelIcon({ style }) {
  return (
    <img
      style={style}
      alt="import-icon"
      src={require("./import/import.svg").default}
    />
  );
}

ImportExcelIcon.propTypes = {
  style: PropTypes.shape({}),
};

ImportExcelIcon.defaultProps = {
  style: {},
};

export function Copy({ style }) {
  return (
    <img
      style={style}
      alt="copy-icon"
      src={require("./copy/copy.svg").default}
    />
  );
}

Copy.propTypes = {
  style: PropTypes.shape({}),
};

Copy.defaultProps = {
  style: {},
};

export function CloseIcon({ style }) {
  return (
    <img
      style={style}
      alt="close-icon"
      src={require("./close/close.svg").default}
    />
  );
}

CloseIcon.propTypes = {
  style: PropTypes.shape({}),
};

CloseIcon.defaultProps = {
  style: {},
};

export function TransParentCloseIcon({ style }) {
  return (
    <img
      style={style}
      alt="close-icon"
      src={require("./close/transparentClose.svg").default}
    />
  );
}

TransParentCloseIcon.propTypes = {
  style: PropTypes.shape({}),
};

TransParentCloseIcon.defaultProps = {
  style: {},
};

export function BlackCloseIcon({ style }) {
  return (
    <img
      style={style}
      alt="close-icon"
      src={require("./close/blackClose.svg").default}
    />
  );
}

BlackCloseIcon.propTypes = {
  style: PropTypes.shape({}),
};

BlackCloseIcon.defaultProps = {
  style: {},
};

export function ResetIcon() {
  return <img alt="Reset" src={require("./reset/reset.svg").default} />;
}
