const EventSubQueueTarget = Object.freeze({
  RETURN_DEPARTMENT_SUBQUEUES: {
    RETURN_DEPARTMENT_NO_SUBQUEUE: "NONE",
    RETURN_DEPARTMENT_SUB_1: "RETURN_DEPARTMENT_SUB_1",
    RETURN_DEPARTMENT_SUB_2: "RETURN_DEPARTMENT_SUB_2",
    RETURN_DEPARTMENT_SUB_3: "RETURN_DEPARTMENT_SUB_3",
    RETURN_DEPARTMENT_SUB_4: "RETURN_DEPARTMENT_SUB_4",
    RETURN_DEPARTMENT_SUB_5: "RETURN_DEPARTMENT_SUB_5",
  },
  CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES: {
    CSD_NO_SUBQUEUE: "CSD_SUB_NONE",
    CSD_OPEN_CASES: "CSD_OPEN_CASES",
  },
  SCC_SUBQUEUES: {
    SCC_NO_SUBQUEUE: "SCC_SUB_NONE",
    SCC_OPEN_CASES: "SCC_OPEN_CASES",
  },
});

export default EventSubQueueTarget;
