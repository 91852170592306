import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import OriolaThemeProvider from "@oriola-origo/core/lib/Theme/OriolaThemeProvider";
import NavigationPane from "@oriola-origo/core/lib/NavigationPane";
import { withRouter, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Paths } from "../../../utils/navigation/navigation";
import { getObjectFromUrlParams } from "../../../utils/url/url";

const TOP_MENU_HEIGHT = 69;

function AppNavigationPane({
  loadingPanel,
  allowedAddNewCase,
  location,
  history,
  isMobileScreen,
}) {
  const { t } = useTranslation();
  const [selectedId, setSelectedId] = useState("cases-button");
  const { organizationId } = getObjectFromUrlParams(location.search);

  useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("newCase")) {
      setSelectedId("new-case-button");
    } else {
      setSelectedId("cases-button");
    }
  }, [location]);

  const links = [
    {
      id: "cases-button",
      path: Paths.Cases,
      text: t("myCases"),
      iconName: "format_list_bulleted",
      iconStyle: "outlined",
    },
  ];

  if (allowedAddNewCase) {
    links.unshift({
      id: "new-case-button",
      path: Paths.NewCase,
      text: t("newCase"),
      iconName: "playlist_add",
      iconStyle: "outlined",
    });
  }

  const handleNavigationPaneButtonClick = id => {
    setSelectedId(id);
    let historyEntry = id;
    if (id === "cases-button" && organizationId) {
      historyEntry = `${Paths.Cases}?organizationId=${organizationId}`;
    }
    history.push(historyEntry);
  };

  return (
    <OriolaThemeProvider>
      <NavigationPane
        links={loadingPanel ? [] : links}
        active={selectedId}
        onSelected={handleNavigationPaneButtonClick}
        linkComponent={Link}
        customStyles={{
          boxShadow: "none",
          height: !isMobileScreen
            ? `calc(100vh - ${TOP_MENU_HEIGHT}px)`
            : "auto",
          "& > div": isMobileScreen
            ? {
                display: "flex",
                flexGrow: 1,
              }
            : {},
          "& div > a": isMobileScreen
            ? {
                maxWidth: "none",
              }
            : {},
        }}
        isHorizontal={isMobileScreen}
      />
    </OriolaThemeProvider>
  );
}

AppNavigationPane.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  loadingPanel: PropTypes.bool,
  allowedAddNewCase: PropTypes.bool,
  isMobileScreen: PropTypes.bool,
};

AppNavigationPane.defaultProps = {
  location: {},
  history: {},
  loadingPanel: false,
  allowedAddNewCase: false,
  isMobileScreen: false,
};

export default withRouter(AppNavigationPane);
