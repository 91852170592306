import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Box from "@oriola-origo/core/lib/Box";
import Title from "../title/title";
import { TitleInput } from "../../common";
import DateAndEditor from "../dateAndEditor";
import { CaseType } from "../../../constants/caseType";
import {
  updateValidationError,
  updateLocalHandlingDataField,
} from "../../../redux";
import { getSpdModificationData } from "../../../utils/getSpdModificationData/getSpdModificationData";
import {
  getValidationErrorMessage,
  FieldErrors,
} from "../../../utils/validations/validations";

function CaseMonetaryValue() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { caseMonetaryValueError } = useSelector(
    state => state.validationErrors
  );
  const { caseType } = useSelector(state => state.case.case);
  const { localHandlingData = {} } = useSelector(state => state.case);
  const { userData } = useSelector(state => state.user);
  const { modifications = {}, caseMonetaryValue = "" } = localHandlingData;
  const getModificationData = field =>
    getSpdModificationData(modifications, `${field}`);

  const handleChange = input => {
    if (input && Number.isNaN(Number(input))) {
      dispatch(
        updateValidationError({
          caseMonetaryValueError: FieldErrors.INVALID_NUMBER,
        })
      );
    } else {
      dispatch(updateValidationError({ caseMonetaryValueError: "" }));
    }
    dispatch(updateLocalHandlingDataField(input, "caseMonetaryValue"));
  };

  return (
    <Box id="case-value" className="handling-section">
      <Title title={t("caseMonetaryValue.title")} />
      <Box mt={2}>
        <TitleInput
          id="case-value-input"
          mt={4}
          value={caseMonetaryValue || ""}
          onChange={event => {
            handleChange(event);
          }}
          rows={1}
          helperText={getValidationErrorMessage(caseMonetaryValueError, t)}
          error={!!caseMonetaryValueError}
        />
        {caseType === CaseType.SUSPECTED_PRODUCT_DEFECT &&
          getModificationData("caseMonetaryValue") && (
            <DateAndEditor
              id="case-value-info"
              modificationData={getModificationData("caseMonetaryValue")}
              user={userData}
            />
          )}
      </Box>
    </Box>
  );
}

export default CaseMonetaryValue;
