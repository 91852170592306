import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import SelectMenu from "./selectMenu";
import EventQueueTarget from "../../../constants/eventQueueTarget";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";

export const ALL_VALUE = "all";

function HandlerSelect({ handler, onHandlerSelected, ...containerProps }) {
  const { t } = useTranslation();
  const all = t("caseStatusFilter.all");

  const getEventQueueTargetLabel = value =>
    value ? t(`queues.${value}`) : "-";

  const getEventSubQueueTargetLabel = value =>
    value ? t(`subQueues.${value}`) : "-";

  const subOptionsReturn = Object.values(
    EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES
  ).map(value => ({
    value,
    label: getEventSubQueueTargetLabel(value),
    subOption: true,
  }));

  const subOptionsScc = Object.values(EventSubQueueTarget.SCC_SUBQUEUES).map(
    value => ({
      value,
      label: getEventSubQueueTargetLabel(value),
      subOption: true,
    })
  );

  const subOptionsCsd = Object.values(
    EventSubQueueTarget.CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES
  ).map(value => ({
    value,
    label: getEventSubQueueTargetLabel(value),
    subOption: true,
  }));

  const options = [{ value: ALL_VALUE, label: all }].concat(
    Object.values(EventQueueTarget).reduce((acc, value) => {
      acc.push({
        value,
        label: getEventQueueTargetLabel(value),
      });
      if (value === EventQueueTarget.RETURN_DEPARTMENT) {
        acc.push(...subOptionsReturn);
      } else if (value === EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT) {
        acc.push(...subOptionsCsd);
      } else if (value === EventQueueTarget.SCC) {
        acc.push(...subOptionsScc);
      }
      return acc;
    }, [])
  );

  const handleHandlerSelected = value => {
    if (value === ALL_VALUE) {
      onHandlerSelected(null);
    } else if (
      Object.values(EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES).includes(
        value
      )
    ) {
      onHandlerSelected(EventQueueTarget.RETURN_DEPARTMENT, value);
    } else if (
      Object.values(
        EventSubQueueTarget.CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES
      ).includes(value)
    ) {
      onHandlerSelected(EventQueueTarget.CUSTOMER_SERVICE_DEPARTMENT, value);
    } else if (
      Object.values(EventSubQueueTarget.SCC_SUBQUEUES).includes(value)
    ) {
      onHandlerSelected(EventQueueTarget.SCC, value);
    } else {
      onHandlerSelected(value);
    }
  };

  return (
    <SelectMenu
      width="100%"
      optionValue={handler || options[0].value}
      options={options}
      onSelectOption={handleHandlerSelected}
      label={t("handler")}
      {...containerProps}
    />
  );
}

HandlerSelect.propTypes = {
  handler: PropTypes.string,
  onHandlerSelected: PropTypes.func.isRequired,
};

HandlerSelect.defaultProps = {
  handler: null,
};

export default HandlerSelect;
