import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { OriolaColors } from "@oriola-origo/origo-ui-core";
import { useTranslation } from "react-i18next";
import MessageAction from "./messageAction";
import EventSubQueueTarget from "../../../constants/eventSubQueueTarget";

const useStyles = makeStyles(theme => ({
  text: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
  },
  textBold: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryDarkGray,
    marginRight: theme.spacing(0.5),
    fontWeight: "bold",
  },
  textBoldHighlight: {
    ...theme.typography.body2,
    color: OriolaColors.secondaryNavyBlue,
    fontWeight: "bold",
  },
}));

function QueueChange({
  message,
  updatingReadStatus,
  updateReadStatusError,
  onModifyMessage,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  const isSubQueue = queue =>
    [
      ...Object.values(EventSubQueueTarget.RETURN_DEPARTMENT_SUBQUEUES),
      ...Object.values(
        EventSubQueueTarget.CUSTOMER_SERVICE_DEPARTMENT_SUBQUEUES
      ),
      ...Object.values(EventSubQueueTarget.SCC_SUBQUEUES),
    ].includes(queue);

  const getQueueName = queue =>
    // Display subQueue as source queue if available
    isSubQueue(queue) ? t(`subQueues.${queue}`) : t(`queues.${queue}`);
  const getSubQueueName = subQueue => t(`subQueues.${subQueue}`);

  const { sourceQueue, destinationQueue, destinationSubQueue } = message;
  // Only mark queue changes with read flag (not old queue change messages)
  const showReadStatus = typeof message.read === "boolean";
  return (
    <MessageAction
      message={message}
      additionalMessage={t("changeQueue.internalMessage")}
      showReadStatus={showReadStatus}
      updateReadStatusError={updateReadStatusError}
      updatingReadStatus={updatingReadStatus}
      onModifyMessage={onModifyMessage}
    >
      <Typography noWrap className={classes.textBold}>
        {t("queueChanged").toUpperCase()}
      </Typography>
      <Typography noWrap className={classes.text}>
        {t("from").toLowerCase()}
      </Typography>
      <Typography noWrap className={classes.textBold}>
        {getQueueName(sourceQueue).toUpperCase()}
      </Typography>
      <Typography noWrap className={classes.text}>
        {t("to").toLowerCase()}
      </Typography>
      <Typography noWrap className={classes.textBoldHighlight}>
        {destinationSubQueue
          ? getSubQueueName(destinationSubQueue).toUpperCase()
          : getQueueName(destinationQueue).toUpperCase()}
      </Typography>
    </MessageAction>
  );
}

QueueChange.propTypes = {
  message: PropTypes.shape({
    sourceQueue: PropTypes.string,
    destinationQueue: PropTypes.string,
    destinationSubQueue: PropTypes.string,
    read: PropTypes.bool,
  }).isRequired,
  updatingReadStatus: PropTypes.bool,
  updateReadStatusError: PropTypes.shape({}),
  onModifyMessage: PropTypes.func,
};

QueueChange.defaultProps = {
  updatingReadStatus: false,
  updateReadStatusError: null,
  onModifyMessage: null,
};

export default QueueChange;
