import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { TooltipTypography } from "@oriola-origo/origo-ui-core";

const useStyles = makeStyles(theme => ({
  columnValueText: {
    color: theme.palette.text.primary,
    width: "100%",
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function CaseHandler({ singleCase, ...rest }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const getQueue = () => {
    if (singleCase.subQueue) {
      return t(`subQueues.${singleCase.subQueue}`);
    }
    return singleCase.queue ? t(`queues.${singleCase.queue}`) : "-";
  };

  const getHandlerName = () => (singleCase.reservation || {}).lastName;

  const getQueueAndHandler = () =>
    [getQueue(), getHandlerName()].filter(x => x).join(" / ");

  const value = getQueueAndHandler();

  return (
    <div {...rest}>
      <TooltipTypography
        className={classes.columnValueText}
        color="textPrimary"
        noWrap
        tooltip={value}
      >
        {value}
      </TooltipTypography>
    </div>
  );
}

CaseHandler.propTypes = {
  singleCase: PropTypes.shape({
    subQueue: PropTypes.string,
    queue: PropTypes.string,
    reservation: PropTypes.shape({
      lastName: PropTypes.string,
    }),
  }),
};

CaseHandler.defaultProps = {
  singleCase: {},
};

export default CaseHandler;
